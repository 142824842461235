import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axios';
import { API } from '../../apis';
import {
  columns,
  orderType,
  productClassificationType,
  processItems,
} from 'src/utils/digitization';
import { v4 } from 'uuid';
import toast from 'src/utils/snackBarUtils';
import { getDatesFromTodayToMonthAgo } from 'src/utils/formatTime';

export const getProductsByArticle = createAsyncThunk(
  'digitization/getProductsByArticle',
  async (params) => {
    try {
      const response = await axiosInstance.get(API.seller_digit_article, {
        params,
      });
      return response.data;
    } catch (error) {
      const errorMessage = error.response
        ? error.response.data
        : 'Что-то не так с сервером. Попробуйте позже.';
      toast.error(errorMessage, {
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      });
    }
  },
);

export const getProductsBySize = createAsyncThunk(
  'digitization/getProductsBySize',
  async (params) => {
    try {
      const response = await axiosInstance.get(API.seller_digit_size, {
        params,
      });
      return response.data;
    } catch (error) {
      const errorMessage = error.response
        ? error.response.data
        : 'Что-то не так с сервером. Попробуйте позже.';
      toast.error(errorMessage, {
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      });
    }
  },
);

const initialState = {
  isProductsByArticleLoading: true,
  isProductsBySizeLoading: true,
  columns,
  filters: {
    productClassificationType: productClassificationType.article,
    orderType: orderType.order,
  },
  key: '23',
  data: {
    [productClassificationType.size]: {
      [orderType.sale]: [],
      [orderType.order]: [],
    },
    [productClassificationType.article]: {
      [orderType.sale]: [],
      [orderType.order]: [],
    },
    dates: [],
  },
};

const digitization = createSlice({
  name: 'digitization',
  initialState,
  reducers: {
    columnToggle: (state, { payload }) => {
      const columns = state.columns;

      const mapColumns = columns[payload.type].map((column) => {
        if (column._id === payload.id) {
          return {
            ...column,
            visible: payload.value,
          };
        }
        return column;
      });

      state.columns[payload.type] = mapColumns;
      state.key = v4();
    },
    setFilters: (state, { payload }) => {
      const key = payload.key;
      const value = payload.value;

      state.filters[key] = value;
      state.key = v4();
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProductsByArticle.pending, (state) => {
        state.isProductsByArticleLoading = true;
      })
      .addCase(getProductsByArticle.rejected, (state) => {
        state.isProductsByArticleLoading = false;
      })
      .addCase(getProductsByArticle.fulfilled, (state, action) => {
        const article = action.payload || [];

        const sales = processItems(article, 'sales');
        const orders = processItems(article, 'orders');

        const productsByArticle = {
          [orderType.sale]: sales,
          [orderType.order]: orders,
        };

        state.data[productClassificationType.article] = productsByArticle;
        state.data.dates = getDatesFromTodayToMonthAgo();
        state.isProductsByArticleLoading = false;
      })
      .addCase(getProductsBySize.pending, (state) => {
        state.isProductsBySizeLoading = true;
      })
      .addCase(getProductsBySize.rejected, (state) => {
        state.isProductsBySizeLoading = false;
      })
      .addCase(getProductsBySize.fulfilled, (state, action) => {
        const size = action.payload || [];

        const sales = processItems(size, 'sales');
        const orders = processItems(size, 'orders');

        const productsBySize = {
          [orderType.sale]: sales,
          [orderType.order]: orders,
        };

        state.data[productClassificationType.size] = productsBySize;
        state.data.dates = getDatesFromTodayToMonthAgo();
        state = state.isProductsBySizeLoading = false;
      });
  },
});

export const digitizationAction = digitization.actions;
export default digitization;
