import { Stack } from '@mui/material';
import styles from './Loader.module.css';

export default function Loader({ rootStyle, visible = true }) {
  if (!visible) {
    return null;
  }

  return (
    <Stack direction="row" justifyContent="center" className={rootStyle}>
      <div className={styles.loader} />
    </Stack>
  );
}
