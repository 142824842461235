import {
  createAsyncThunk,
  createSlice,
  createSelector,
} from '@reduxjs/toolkit';
import { API } from 'src/apis';
import { endSession, isValidToken, setSession } from 'src/utils/jwt';
import { WILDBERRIES } from 'src/utils/marketplace';
import { setLanguage } from './common';
import axiosInstance from 'src/utils/axios';
import toast from 'src/utils/snackBarUtils';
import { getPaymentStatus } from './user';
import { actionMarketplace } from './marketplace';
import { v4 } from 'uuid';

const name = 'authentication';

export const authInitializeThunk = createAsyncThunk(
  `${name}/authInitializeThunk`,
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const accessToken = localStorage.getItem('accessToken');

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

        const { data: user } = await axiosInstance.get(API.user);

        dispatch(authGetStoreThunk());

        return {
          isAuthenticated: true,
          user: {
            ...user,
            first_name: user.first_name,
            photoURL: '',
            email: user.email || '',
            phone: user.phone || '',
            is_user_added_token: user.is_user_added_token || '',
            organization_name: user.organization_name || '',
          },
        };
      }

      endSession();

      return {
        isAuthenticated: false,
        user: null,
      };
    } catch (error) {
      toast.warning('При загрузке данных произошла ошибка', {
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        autoHideDuration: 5000,
      });
      return rejectWithValue(error);
    }
  },
);

export const authGetStoreThunk = createAsyncThunk(
  `${name}/authGetStoreThunk`,
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axiosInstance.get(API.store);

      dispatch(setLanguage(data.settings.language || 'ru'));
      dispatch(actionMarketplace.switchMarketplace(data.current_marketplace));

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const authLoginThunk = createAsyncThunk(
  `${name}/authLoginThunk`,
  async ({ email, password }, { rejectWithValue, dispatch }) => {
    try {
      const {
        data: { access_token, user },
      } = await axiosInstance.post(API.signin, {
        email,
        password,
      });

      setSession(access_token);

      dispatch(getPaymentStatus());

      return user;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const authLoginGoogleCodeThunk = async (code) => {
  try {
    const { data } = await axiosInstance.post('/dj-rest-auth/google/', {
      code,
    });

    setSession(data.accessToken);
  } catch (error) {
    if (error instanceof Error) {
      toast.error(error.message, {
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        autoHideDuration: 5000,
      });
    } else {
      toast.error(
        'Извините, возникли проблемы с авторизацией из-за неполадок на сервере. Мы работаем над исправлением. Пожалуйста, попробуйте позже.',
        {
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          autoHideDuration: 5000,
        },
      );
    }
  }
};

export const authUpdateProfileThunk = createAsyncThunk(
  `${name}/authUpdateProfileThunk`,
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axiosInstance.patch(API.user, payload);

      dispatch(getPaymentStatus());
      return {
        isAuthenticated: true,
        user: {
          first_name: data?.first_name,
          photoURL: '',
          email: data?.email,
          phone: data?.phone,
          is_user_added_token: data?.is_user_added_token,
          organization_name: data.organization_name || '',
        },
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const authVerifyEmailThunk = createAsyncThunk(
  `${name}/authVerifyEmailThunk`,
  async (key, { rejectWithValue, dispatch }) => {
    try {
      const {
        data: { access_token, user },
      } = await axiosInstance.post(API.verify_email, {
        key,
      });
      dispatch(getPaymentStatus());
      setSession(access_token);

      return user;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const authRegisterThunk = createAsyncThunk(
  `${name}/authRegisterThunk`,
  async ({ email, password }, { rejectWithValue, dispatch }) => {
    try {
      const {
        data: { access_token, user },
      } = await axiosInstance.post(API.signin, {
        email,
        password,
      });

      dispatch(getPaymentStatus());

      setSession(access_token);

      return user;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const authUpdateStoreThunk = createAsyncThunk(
  `${name}/authUpdateStoreThunk`,
  async (payload, { rejectWithValue, getState }) => {
    const state = getState();

    const store = state?.auth?.obj?.store;
    try {
      const payloadSettings = payload?.settings || {};
      const storeSettings = store?.settings || {};

      const data = {
        ...store,
        ...payload,
        settings: { ...storeSettings, ...payloadSettings },
      };

      const response = await axiosInstance.patch(API.store, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const authRefreshStoreDataThunk = createAsyncThunk(
  `${name}/authRefreshStoreDataThunk`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(API.pull_data);

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const authGetTaskStatusThunk = createAsyncThunk(
  `${name}/authGetTaskStatusThunk`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(API.task_status);

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const initialState = {
  obj: {
    isAuthenticated: false,
    isInitialized: false,
    user: null,
    store: {
      allow_push_notifications: false,
      name: '',
      seller_id: '',
      settings: {
        kaspi_username: '',
        time_filter: 'month',
        brand_id: '',
      },
      current_marketplace: WILDBERRIES,
      is_pulling_data: false,
    },
    method: 'jwt',
    refreshKey: 'refreshkey',
  },
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    logout: (state) => {
      setSession(null);

      state.obj.isAuthenticated = false;
      state.obj.user = null;

      endSession();
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(authInitializeThunk.fulfilled, (state, action) => {
        const { isAuthenticated, user } = action.payload;

        state.obj.isAuthenticated = isAuthenticated;
        state.obj.isInitialized = true;
        state.obj.user = user;
      })
      .addCase(authInitializeThunk.rejected, (state, actions) => {
        state.obj = {
          ...state.obj,
          isAuthenticated: true,
          isInitialized: true,
          user: {
            first_name: '',
            photoURL: '',
            email: '',
            phone: '',
            is_user_added_token: '',
          },
        };
      });

    builder.addCase(authUpdateProfileThunk.fulfilled, (state, action) => {
      const { isAuthenticated, user } = action.payload;

      state.obj.isAuthenticated = isAuthenticated;
      state.obj.isInitialized = true;
      state.obj.user = user;
    });

    builder.addCase(authGetStoreThunk.fulfilled, (state, actions) => {
      const store = actions.payload;

      state.obj.store = store;
    });

    builder.addCase(authLoginThunk.fulfilled, (state, actions) => {
      const user = actions.payload;

      state.obj.isAuthenticated = true;
      state.obj.user = user;
    });

    builder.addCase(authVerifyEmailThunk.fulfilled, (state, actions) => {
      const user = actions.payload;

      state.obj.isAuthenticated = true;
      state.obj.user = user;
    });

    builder.addCase(authRegisterThunk.fulfilled, (state, actions) => {
      const user = actions.payload;

      state.obj.isAuthenticated = true;
      state.obj.user = user;
    });

    builder.addCase(authUpdateStoreThunk.fulfilled, (state, actions) => {
      const store = actions.payload;

      state.obj.store = store;
    });

    builder.addCase(authRefreshStoreDataThunk.pending, (state) => {
      state.obj.store.is_pulling_data = true;
    });

    builder.addCase(authRefreshStoreDataThunk.fulfilled, (state, actions) => {
      const status = !actions.payload?.data?.is_ready;
      const dataState = actions.payload.data.state;

      if (dataState !== 'failed') {
        state.obj.store.is_pulling_data = status;
      }
    });

    builder.addCase(authGetTaskStatusThunk.fulfilled, (state, actions) => {
      const status = !actions.payload?.data?.is_ready;
      if (!status && state.obj.store.is_pulling_data) {
        state.obj.store.is_pulling_data = status;
        if (actions.payload.data.state === 'SUCCESS') {
          state.obj.refreshKey = v4();
        }
      } else {
        state.obj.store.is_pulling_data = status;
      }
    });
  },
});

export const authReduxActions = slice.actions;

export const authReduxSelector = (state) => {
  return state?.auth?.obj;
};

const selectSelf = (state) => state.auth;
export const isUserAddedTokenSelector = createSelector(
  selectSelf,
  (state) => state.obj?.user.is_user_added_token || false,
);

export default slice.reducer;

export const register = async (auth) => {
  await axiosInstance.post(API.singup, {
    ...auth,
  });
};

export const changePassword = async (data) => {
  await axiosInstance.post(API.change, data);
};

export const resetPasswordVerify = async (password1, password2, uid, token) => {
  await axiosInstance.post(API.confirm, {
    new_password1: password1,
    new_password2: password2,
    uid,
    token,
  });
};

export const resetPassword = async (email) => {
  await axiosInstance.post(API.reset, {
    email,
  });
};
